export default {
  loadLineChartSuccess(state, { type, responses }) {
    responses.forEach((response) => {
      const chart = response.chart;
      console.log(chart);
      let data = response.data;
      if (['WEEK', 'MONTH', 'YEAR'].includes(type)) {
        const temp = [...data];
        data = {
          preventTotal: temp[0].preventTotal,
          total: temp.reduce((total, d) => total + d.value, 0),
          data: temp.map((d) => d.value),
          label: temp.map((d) => d[type === 'YEAR' ? 'month' : 'date']),
        };
      } else {
        data = data[0];
        data.data = Object.keys(data).reduce((array, item) => {
          if (!['percent', 'total'].includes(item)) {
            array.push(data[item]);
          }
          return array;
        }, []);
        data.label = ['0', '4', '8', '12', '16', '20', '24'];
      }
      state[chart].label = data.label;
      state[chart].data = data.data;
      state[chart].preventTotal = data.preventTotal;
      state[chart].total = data.total;
      state[chart].type = 'trending-up';
      if (data.preventTotal && data.total / data.preventTotal < 1) {
        state[chart].type = 'trending-down';
      }
    });
  },
  loadTotalArticleByStatus(state, data) {
    console.log(data);
    state.totalArticleByStatus = data;
  },
  loadTotalArticleByCreated(state, data) {
    state.totalArticleByCreated = data;
  },
};
