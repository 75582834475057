import mutations from '@/vuex/modules/document/mutations';
import { getField, updateField } from 'vuex-map-fields';
import { DataService } from '@/dataService/dataService';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { MESSAGES } from '@/util/message-notification';
import { showConfirm } from '@/util/confirm';
import ConstantAPI from '@/config/ConstantAPI';

const state = () => ({
  list: [],
  listAll: [],
  loading: false,
  topic: {},
  api: null,
  visible: false,
  action: null,
  documentTypes: [],
  documentFields: [],
  documentGroups: [],
  documentMajors: [],
  agencyRelease: [],
  agencyReceive: [],
});

const actions = {
  changeApi({ commit }, api) {
    commit('setApi', api);
  },
  changeAction({ commit }, action) {
    commit('setAction', action);
  },
  async fetchDocument({ state }) {
    let response = await DataService.callApi(ConstantAPI.config.agency.GET_ALL);
    state.agencyRelease = (response.data || []).filter((e) => (e.status = 1));
    state.agencyReceive = (response.data || []).filter((e) => (e.status = 2));
    response = await DataService.callApi(ConstantAPI.config.majors.GET_ALL);
    state.documentMajors = response.data || [];
    response = await DataService.callApi(ConstantAPI.config.fields.GET_ALL);
    state.documentFields = response.data || [];
    response = await DataService.callApi(ConstantAPI.config.groups.GET_ALL);
    state.documentGroups = response.data || [];
    response = await DataService.callApi(ConstantAPI.config.types.GET_ALL);
    state.documentTypes = response.data || [];
  },
  async create({ commit, state }, payload) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.CREATE, payload);
      checkResponse(response, () => {
        notification.success({
          message: 'Thông báo',
          description: MESSAGES.MESSAGE_005,
          duration: 4,
        });
      });
      return commit('setLoading', false);
    } catch (err) {
      console.log(err);
    }
  },
  async update({ commit, state }, payload) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.UPDATE, payload);
      checkResponse(response, () => {
        notification.success({
          message: 'Thông báo',
          description: MESSAGES.MESSAGE_007,
          duration: 4,
        });
      });
      return commit('setLoading', false);
    } catch (err) {
      console.log(err);
    }
  },
  async delete({ state }, rows) {
    try {
      if (await showConfirm('Bạn có chắc chắn muốn xóa không?')) {
        const response = await DataService.delete(
          state.api.DELETE.url + rows.map((e) => e.id).join(',')
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Xóa thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
