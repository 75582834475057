import { FORM_MODE } from '@/util/common-constant';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  getAllSuccess(state, data) {
    state.list = data;
    state.loading = false;
  },
  getCategoriesSuccess(state, data) {
    state.categories = data;
  },
  preCreate(state) {
    state.visible = true;
    state.formMode = FORM_MODE.CREATE;
    state.introduce = {
      id: null,
      title: '',
      status: 1,
      content: '',
    };
  },
  setFormValue(state, { introduce, formMode }) {
    state.visible = true;
    state.formMode = formMode;
    state.introduce = {
      ...introduce,
    };
  },
};
