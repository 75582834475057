import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';

const state = () => ({
  list: [],
  api: ConstantAPI.APP_PARAM,
  visible: false,
  listTypeOfWork: [],
  listExperience: [],
  listEmployeeLevel: [],
  listWorkplace: [],
  listAllCompany: [],
});

const actions = {
  async getFullParam({ commit, state }) {
    try {
      const response = await DataService.callApi(state.api.SEARCH);
      return commit('getAllSuccess', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getByConditions({ commit, state }, param) {
    try {
      const response = await DataService.callApi(state.api.SEARCH, null, param);
      return commit('setDataToList', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getAllCompany({ commit, state }) {
    try {
      const response = await DataService.callApi(state.api.GET_ALL_COMPANY);
      return commit('setAllCompany', response.data);
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
