import mutations from '../contact/mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { showConfirm } from '@/util/confirm';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
const state = () => ({
  list: [],
  listAll: [],
  loading: false,
  contact: {},
  api: ConstantAPI.CONTACT,
  visible: false,
});

const actions = {
  async updateView(store, id) {
    try {
      await DataService.put(store.state.api.updateView.url + id);
    } catch (err) {
      console.log(err);
    }
  },
  async getAll({ commit, state }) {
    try {
      const response = await DataService.get(state.api.getAll.url);
      return commit('getAllSuccess', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async delete({ state }, rows) {
    try {
      if (!rows.length) {
        notification.error({
          message: 'Thông báo',
          description: 'Không có bản ghi được chọn',
          duration: 4,
        });
        return false;
      }
      if (await showConfirm('Bạn có chắc chắn muốn xóa không?')) {
        const response = await DataService.delete(
          state.api.delete.url + rows.map((e) => e && e.id).join(',')
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Xóa liên hệ thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
