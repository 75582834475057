import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { checkResponse } from '@/util/common-utils';
import { MESSAGES } from '@/util/message-notification';
import { notification } from 'ant-design-vue';
import mutations from '@/vuex/modules/topics/mutations';
import { getField, updateField } from 'vuex-map-fields';
import { showConfirm } from '@/util/confirm';

const state = () => ({
  list: [],
  listAll: [],
  loading: false,
  topic: {},
  api: ConstantAPI.topics,
  visible: false,
  action: null,
});

const actions = {
  changeAction({ commit }, action) {
    commit('setAction', action);
  },
  async getAll({ commit, state }) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.GET_ALL);
      return commit('setAll', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async create({ commit, state }, payload) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.CREATE, payload);
      checkResponse(response, () => {
        notification.success({
          message: 'Thông báo',
          description: MESSAGES.MESSAGE_005,
          duration: 4,
        });
      });
      return commit('setLoading', false);
    } catch (err) {
      console.log(err);
    }
  },
  async getById({ commit, state }, id) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi({
        method: 'GET',
        url: `${state.api.GET_BY_ID.url + id}`,
      });
      commit('setData', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async update({ commit, state }, payload) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.UPDATE, payload);
      checkResponse(response, () => {
        notification.success({
          message: 'Thông báo',
          description: MESSAGES.MESSAGE_007,
          duration: 4,
        });
      });
      return commit('setLoading', false);
    } catch (err) {
      console.log(err);
    }
  },
  async delete({ state }, rows) {
    try {
      if (await showConfirm('Bạn có chắc chắn muốn xóa không?')) {
        const response = await DataService.delete(
          state.api.DELETE.url + rows.map((e) => e.id).join(',')
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Xóa Topic thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
