import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {checkResponse} from '@/util/common-utils';
import {MESSAGES} from '@/util/message-notification';
import {notification} from 'ant-design-vue';
import mutations from './mutations';
import {getField, updateField} from 'vuex-map-fields';

const state = () => ({
  list: [],
  listAll: [],
  loading: false,
  detail: null,
  api: ConstantAPI.recruitment,
  visible: false,
  action: null,
});

const actions = {
  changeAction({ commit }, action) {
    commit('setAction', action);
  },
  async getAll({ commit, state }) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.GET_ALL);
      return commit('setAll', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async create({ commit, state }, payload) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.CREATE, payload);
      checkResponse(response, () => {
        notification.success({
          message: 'Thông báo',
          description: MESSAGES.MESSAGE_005,
          duration: 4,
        });
      });
      return commit('setLoading', false);
    } catch (err) {
      console.log(err);
    }
  },
  async getDetail({ commit, state }, param) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(
        state.api.GET_BY_CODE,
        null,
        param
      );
      commit('setData', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async update({ commit, state }, payload) {
    try {
      commit('setLoading', true);
      let isSuccess  = false
      const response = await DataService.callApi(state.api.UPDATE, payload);
      checkResponse(response, () => {
        isSuccess = true
        notification.success({
          message: 'Thông báo',
          description: MESSAGES.MESSAGE_007,
          duration: 4,
        });
      });
      commit('setLoading', false)
      return isSuccess
    } catch (err) {
      console.log(err);
    }
  },
  async delete({ state }, rows) {
    try {
      const response = await DataService.delete(
          state.api.DELETE.url + rows.map((e) => e.code).join(',')
      );
      let success = false;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: 'Thông báo',
          description: 'Xóa Tin tuyển dụng thành công',
          duration: 4,
        });
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  resetData({ state }) {
    state.detail = null;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
