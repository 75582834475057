import { APP_PARAM_TYPE } from '@/util/common-constant';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  getAllSuccess(state, data) {
    state.list = data;
  },
  setDataToList(state, data) {
    if (data && data.length > 0) {
      data.forEach((e) => {
        if (e.type === APP_PARAM_TYPE.TYPE_OF_WORK) {
          state.listTypeOfWork = e.detail;
        }
        if (e.type === APP_PARAM_TYPE.EMPLOYEE_LEVEL) {
          state.listEmployeeLevel = e.detail;
        }
        if (e.type === APP_PARAM_TYPE.WORKPLACE) {
          state.listWorkplace = e.detail;
        }
        if (e.type === APP_PARAM_TYPE.EXPERIENCE) {
          state.listExperience = e.detail;
        }
      });
    }
  },
  setAllCompany(state, data) {
    state.listAllCompany = data;
  },
};
