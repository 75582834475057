import { FORM_MODE } from '@/util/common-constant';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  getAllSuccess(state, data) {
    state.list = data;
    state.loading = false;
  },
  preCreate(state) {
    state.visible = true;
    state.formMode = FORM_MODE.CREATE;
    state.legislation = {
      id: null,
      title: '',
      status: 1,
      general: '',
      sequence: '',
      profile: '',
      condition: '',
    };
  },
  setFormValue(state, { legislation, formMode }) {
    state.visible = true;
    state.formMode = formMode;
    state.legislation = {
      ...legislation,
    };
  },
};
