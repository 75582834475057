import { FORM_MODE } from '@/util/common-constant';
import { flatten } from '@/util/common-utils';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  getAllSuccess(state, data) {
    state.list = data;
    state.loading = false;
  },
  getAll(state, data) {
    console.log('listAll', data);
    state.listAll = data.map((e) => ({
      value: e.code,
      label: e.titleVi + ' - ' + e.titleEn,
    }));
    // state.listAll = data;
    state.categoriesFlatten = flatten(data);
    state.loading = false;
  },
  getAllWithoutTree(state, data) {
    state.listAllWithoutTree = data;
    state.categoriesFlatten = flatten(data);
    state.loading = false;
  },
  preCreate(state) {
    state.visible = true;
    state.formMode = FORM_MODE.CREATE;
    state.category = {
      id: null,
      name: '',
      url: '',
      status: 1,
      isShow: 1,
      orderNum: null,
      thumbPath: [],
      icon: [],
    };
  },
  setFormValue(state, { category, formMode }) {
    state.visible = true;
    state.formMode = formMode;
    state.category = {
      ...category,
      thumbPath: category.thumbPath ? [category.thumbPath] : [],
      icon: category.icon ? [category.icon] : [],
    };
  },
  setAction(state, action) {
    state.action = action;
  },
  setCategory(state, category) {
    state.category = category;
  },
};
