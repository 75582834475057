import { createStore } from 'vuex';
import themeLayout from './modules/themeLayout/actionCreator';
import auth from './modules/authentication/axios/actionCreator';
import role from './modules/system/role/actionCreator';
import menu from './modules/system/menu/actionCreator';
import user from './modules/system/user/actionCreator';
import chartContent from './modules/chartContent/actionCreator';
import clip from './modules/clip/clip/actionCreator';
import category from './modules/category/actionCreator';
import reportNews from './modules/report/news/actionCreator';
import articles from './modules/articles/actionCreator';
import mediaFiles from './modules/mediafiles/actionCreator';
import procedure from './modules/procedure/actionCreator';
import legislation from './modules/legislation/actionCreator';
import appParam from './modules/appParam/actionCreator';
import topics from './modules/topics/actionCreator';
import introduce from './modules/introduce/actionCreator';
import banners from './modules/banners/actionCreator';
import qAndA from './modules/qAndA/actionCreator';
import document from './modules/document/actionCreator';
import recruitment from './modules/recruitment/actionCreator';
import contact from './modules/contact/actionCreator';
import caseStudy from './modules/case-study/actionCreator';
export default createStore({
  modules: {
    themeLayout,
    auth,
    role,
    menu,
    user,
    chartContent,
    clip,
    category,
    reportNews,
    articles,
    procedure,
    legislation,
    appParam,
    introduce,
    topics,
    banners,
    qAndA,
    document,
    mediaFiles,
    recruitment,
    contact,
    caseStudy,
  },
});
