import { FORM_MODE } from '@/util/common-constant';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  getAllSuccess(state, data) {
    state.list = data;
    state.loading = false;
  },
  getCategoriesSuccess(state, data) {
    state.categories = data;
  },
  preCreate(state) {
    state.visible = true;
    state.formMode = FORM_MODE.CREATE;
    state.qAndA = {
      id: null,
      question: '',
      answer: '',
      isPublic: 1,
    };
  },
  setFormValue(state, { qAndA, formMode }) {
    state.visible = true;
    state.formMode = formMode;
    state.qAndA = {
      ...qAndA,
    };
  },
};
