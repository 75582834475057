const CMS_SERVICE = '/cms-service';
export default {
  report_service: {
    OVERVIEW: {
      url: CMS_SERVICE + '/overview',
      method: 'GET',
    },
    OVERVIEW_LINE: {
      url: CMS_SERVICE + '/chart',
      method: 'GET',
    },
    TOTAL_ARTICLE_BY_CREATED: {
      url: CMS_SERVICE + '/chart/article-by-created',
      method: 'GET',
    },
    TOTAL_ARTICLE_BY_STATUS: {
      url: CMS_SERVICE + '/chart/article-by-status',
      method: 'GET',
    },
  },
  role: {
    GET_ALL: {
      url: CMS_SERVICE + '/role/get-all',
      method: 'GET',
    },
    SEARCH: {
      url: CMS_SERVICE + '/role',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/role',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/role',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/role',
      method: 'DELETE',
    },
  },
  role_menu: {
    GET_BY_ROLE: {
      url: CMS_SERVICE + '/role-menu',
      method: 'GET',
    },
  },
  user_role: {
    GET_BY_USER: {
      url: CMS_SERVICE + '/user-role',
      method: 'GET',
    },
  },
  category: {
    ALL: {
      url: CMS_SERVICE + '/category/get-all',
      method: 'GET',
    },
    ALL_WITHOUT_TREE: {
      url: CMS_SERVICE + '/category/get-all-without-tree',
      method: 'GET',
    },
    SEARCH: {
      url: CMS_SERVICE + '/category',
      method: 'GET',
    },
    DETAIL: {
      url: CMS_SERVICE + '/category/',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/category',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/category',
      method: 'PUT',
    },
    UPDATE_STATUS: {
      url: CMS_SERVICE + '/category/update-status',
      method: 'PUT',
    },

    DELETE: {
      url: CMS_SERVICE + '/category',
      method: 'DELETE',
    },
  },
  menu: {
    SEARCH: {
      url: CMS_SERVICE + '/menu',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/menu',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/menu',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/menu',
      method: 'DELETE',
    },
  },
  user: {
    SEARCH: {
      url: CMS_SERVICE + '/user',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/user',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/user',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/user',
      method: 'DELETE',
    },
    CHECK_USERNAME_EXISTED: {
      url: CMS_SERVICE + '/user/check',
      method: 'GET',
    },
    GET_USER_INFO: {
      url: CMS_SERVICE + '/user/get-user-info',
      method: 'GET',
    },
    GET_NOTIFICATIONS: {
      url: CMS_SERVICE + '/notify',
      method: 'GET',
    },
    MARK_READ_NOTIFICATION: {
      url: CMS_SERVICE + '/notify/seen',
      method: 'PATCH',
    },
    PATCH_UPDATE: {
      url: CMS_SERVICE + '/user',
      method: 'PATCH',
    },
  },
  auth: {
    LOGIN: {
      url: CMS_SERVICE + '/oauth/token',
      method: 'POST',
    },
    LOGOUT: {
      url: CMS_SERVICE + '/oauth/token/revoke',
      method: 'DELETE',
    },
    PATCH_UPDATE: {
      url: CMS_SERVICE + '/user',
      method: 'PATCH',
    },
  },
  upload_service: {
    UPLOAD: {
      url: CMS_SERVICE + '/upload',
      method: 'POST',
    },
  },
  articles: {
    ALL: {
      url: CMS_SERVICE + '/article/get-all',
      method: 'GET',
    },
    SEARCH: {
      url: CMS_SERVICE + '/article',
      method: 'GET',
    },
    SEARCH_APPROVE: {
      url: CMS_SERVICE + '/article/approve',
      method: 'GET',
    },
    SEARCH_PUBLIC: {
      url: CMS_SERVICE + '/article/publish',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/article',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/article',
      method: 'PUT',
    },
    UPDATE_STATUS: {
      url: CMS_SERVICE + '/article/update-status',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/article',
      method: 'DELETE',
    },
    UN_PUBLISH: {
      url: CMS_SERVICE + '/article/un-publish',
      method: 'PATCH',
    },
    APPROVAL: {
      url: CMS_SERVICE + '/article/approve',
      method: 'PATCH',
    },
    PUBLISH: {
      url: CMS_SERVICE + '/article/publish',
      method: 'PATCH',
    },
    UPDATE_HOT: {
      url: CMS_SERVICE + '/article/update-is-hot',
      method: 'PUT',
    },
  },
  media_files: {
    ALL: {
      url: CMS_SERVICE + '/media-files/get-all',
      method: 'GET',
    },
    SEARCH: {
      url: CMS_SERVICE + '/media-files',
      method: 'GET',
    },
    SEARCH_APPROVE: {
      url: CMS_SERVICE + '/media-files/approve',
      method: 'GET',
    },
    SEARCH_PUBLIC: {
      url: CMS_SERVICE + '/media-files/publish',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/media-files',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/media-files',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/media-files',
      method: 'DELETE',
    },
    UN_PUBLISH: {
      url: CMS_SERVICE + '/media-files/un-publish',
      method: 'PATCH',
    },
    APPROVAL: {
      url: CMS_SERVICE + '/media-files/approve',
      method: 'PATCH',
    },
    PUBLISH: {
      url: CMS_SERVICE + '/media-files/publish',
      method: 'PATCH',
    },
  },
  chart: {
    DOMAIN_IP: {
      url: CMS_SERVICE + '/chart/account-domain-ip',
      method: 'GET',
    },
    ORGANIZATION: {
      url: CMS_SERVICE + '/chart/organization',
      method: 'GET',
    },
    STATUS: {
      url: CMS_SERVICE + '/chart/status',
      method: 'GET',
    },
    SUCCESS_STATUS: {
      url: CMS_SERVICE + '/chart/success-or-not',
      method: 'GET',
    },
    SUMMARY: {
      url: CMS_SERVICE + '/chart/summary',
      method: 'GET',
    },
    MANAGE_DATA: {
      url: CMS_SERVICE + '/agency/get-chart',
      method: 'GET',
    },
    PROVIDE_DATA: {
      url: CMS_SERVICE + 'agency/get-chart-dataset',
      method: 'GET',
    },
    FIELD_DATA: {
      url: CMS_SERVICE + '/fields/chart-field-dataset',
      method: 'GET',
    },
    TOTAL_FILE: {
      url: CMS_SERVICE + '/file/total-files',
      method: 'GET',
    },
    TOTAL_FILE_UPLOAD: {
      url: CMS_SERVICE + '/file/total-files-upload-by-month',
      method: 'GET',
    },
    TOTAL_FILE_DOWNLOAD: {
      url: CMS_SERVICE + '/file/total-files-download-by-month',
      method: 'GET',
    },
    TOTAL_AGENCIES_UPLOADED: {
      url: CMS_SERVICE + '/file/total-agencies-uploaded',
      method: 'GET',
    },
    TOP_FILE_UPLOAD: {
      url: CMS_SERVICE + '/file/top-10',
      method: 'GET',
    },
    TOP_AGENCIES: {
      url: CMS_SERVICE + '/file/top-10-agencies',
      method: 'GET',
    },
    FILE_EXTENSION: {
      url: CMS_SERVICE + '/file/files-extension',
      method: 'GET',
    },
  },
  procedure: {
    SEARCH: {
      url: CMS_SERVICE + '/procedure',
      method: 'GET',
    },
    CATEGORIES: {
      url: CMS_SERVICE + '/procedure/categories',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/procedure',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/procedure',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/procedure',
      method: 'DELETE',
    },
  },
  qAndA: {
    SEARCH: {
      url: CMS_SERVICE + '/qa',
      method: 'GET',
    },
    SEARCH_FA: {
      url: CMS_SERVICE + '/qa/frequently-asked',
      method: 'GET',
    },
    SEARCH_QA: {
      url: CMS_SERVICE + '/qa/q-and-a',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/qa',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/qa',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/qa',
      method: 'DELETE',
    },
  },
  topics: {
    GET_ALL: {
      url: CMS_SERVICE + '/topics/all',
      method: 'GET',
    },
    GET_BY_CONDITIONS: {
      url: CMS_SERVICE + '/topics',
      method: 'GET',
    },
    GET_BY_ID: {
      url: CMS_SERVICE + '/topics/',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/topics/create',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/topics/update',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/topics/',
      method: 'DELETE',
    },
  },
  APP_PARAM: {
    SEARCH: {
      url: CMS_SERVICE + '/app-params',
      method: 'GET',
    },
    GET_ALL_COMPANY: {
      url: CMS_SERVICE + '/company',
      method: 'GET',
    },
  },
  legislation: {
    SEARCH: {
      url: CMS_SERVICE + '/legislation',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/legislation',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/legislation',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/legislation',
      method: 'DELETE',
    },
  },
  introduce: {
    SEARCH: {
      url: CMS_SERVICE + '/introduce',
      method: 'GET',
    },
    CATEGORIES: {
      url: CMS_SERVICE + '/introduce/categories',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/introduce',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/introduce',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/introduce',
      method: 'DELETE',
    },
  },
  banners: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/banners',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/banners/all',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/banners/create',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/banners/update',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/banners/',
      method: 'DELETE',
    },
  },
  config: {
    agency: {
      GET_ALL: {
        url: CMS_SERVICE + '/agency/get-all',
        method: 'GET',
      },
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/agency',
        method: 'GET',
      },
      CREATE: {
        url: CMS_SERVICE + '/agency',
        method: 'POST',
      },
      UPDATE: {
        url: CMS_SERVICE + '/agency',
        method: 'PUT',
      },
      DELETE: {
        url: CMS_SERVICE + '/agency/',
        method: 'PUT',
      },
    },
    majors: {
      GET_ALL: {
        url: CMS_SERVICE + '/document-major/get-all',
        method: 'GET',
      },
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/document-major',
        method: 'GET',
      },
      CREATE: {
        url: CMS_SERVICE + '/document-major',
        method: 'POST',
      },
      UPDATE: {
        url: CMS_SERVICE + '/document-major',
        method: 'PUT',
      },
      DELETE: {
        url: CMS_SERVICE + '/document-major/',
        method: 'PUT',
      },
    },
    groups: {
      GET_ALL: {
        url: CMS_SERVICE + '/document-group/get-all',
        method: 'GET',
      },
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/document-group',
        method: 'GET',
      },
      CREATE: {
        url: CMS_SERVICE + '/document-group',
        method: 'POST',
      },
      UPDATE: {
        url: CMS_SERVICE + '/document-group',
        method: 'PUT',
      },
      DELETE: {
        url: CMS_SERVICE + '/document-group/',
        method: 'PUT',
      },
    },
    fields: {
      GET_ALL: {
        url: CMS_SERVICE + '/document-fields/get-all',
        method: 'GET',
      },
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/document-fields',
        method: 'GET',
      },
      CREATE: {
        url: CMS_SERVICE + '/document-fields',
        method: 'POST',
      },
      UPDATE: {
        url: CMS_SERVICE + '/document-fields',
        method: 'PUT',
      },
      DELETE: {
        url: CMS_SERVICE + '/document-fields/',
        method: 'PUT',
      },
    },
    types: {
      GET_ALL: {
        url: CMS_SERVICE + '/document-type/get-all',
        method: 'GET',
      },
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/document-type',
        method: 'GET',
      },
      CREATE: {
        url: CMS_SERVICE + '/document-type',
        method: 'POST',
      },
      UPDATE: {
        url: CMS_SERVICE + '/document-type',
        method: 'PUT',
      },
      DELETE: {
        url: CMS_SERVICE + '/document-type/',
        method: 'PUT',
      },
    },
  },
  recruitment: {
    GET_ALL: {
      url: CMS_SERVICE + '/recruitment/all',
      method: 'GET',
    },
    GET_BY_CONDITIONS: {
      url: CMS_SERVICE + '/recruitment',
      method: 'GET',
    },
    GET_BY_CODE: {
      url: CMS_SERVICE + '/recruitment/detail',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/recruitment',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/recruitment',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/recruitment/',
      method: 'DELETE',
    },
  },
  CONTACT: {
    getAll: {
      url: CMS_SERVICE + '/contact',
      method: 'GET',
    },
    updateView: {
      url: CMS_SERVICE + '/contact/',
      method: 'PUT',
    },
    delete: {
      url: CMS_SERVICE + '/contact/',
      method: 'DELETE',
    },
  },
  caseStudy: {
    GET_WEB_URL: {
      url: CMS_SERVICE + '/web-url',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/case-study/get-all',
      method: 'GET',
    },
    GET_BY_CONDITIONS: {
      url: CMS_SERVICE + '/case-study',
      method: 'GET',
    },
    GET_BY_CODE: {
      url: CMS_SERVICE + '/case-study/',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/case-study',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/case-study',
      method: 'PUT',
    },
    UPDATE_STATUS: {
      url: CMS_SERVICE + '/case-study/update-status',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/case-study/',
      method: 'DELETE',
    },
  },
};
