import mutations from '../articles/mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { FORM_MODE } from '@/util/common-constant';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { showConfirm } from '@/util/confirm';

const state = () => ({
  list: [],
  listAll: [],
  loading: false,
  articles: {},
  api: ConstantAPI.articles,
  visible: false,
});

const actions = {
  async getSearch({ commit, state }) {
    try {
      const response = await DataService.callApi(state.api.SEARCH);
      return commit('getAllSuccess', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getAll({ commit, state }) {
    try {
      const response = await DataService.callApi(state.api.ALL);
      return commit('getAll', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async preCreate({ commit }) {
    commit('preCreate');
  },
  async preUpdate({ commit, state }, row) {
    const response = await DataService.get(
      state.api.SEARCH.url + '/' + row.code
    );
    if (response.data){
      response.data.tagEn = response.data.tagEn.split(",")
      response.data.tagVi = response.data.tagVi.split(",")
    }
    commit('setFormValue', {
      articles: response.data,
      formMode: FORM_MODE.UPDATE,
    });
  },
  async preView({ commit, state }, row) {
    const response = await DataService.get(
      state.api.SEARCH.url + '/' + row.code
    );
    commit('setFormValue', {
      articles: response.data,
      formMode: FORM_MODE.VIEW,
    });
  },
  async create({ commit, state }, payload) {
    try {
      if (await showConfirm('Bạn có chắc chắn muốn lưu không?')) {
        commit('setLoading', true);
        const response = await DataService.callApi(
          state.api.CREATE,
          payload,
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Thêm mới Bài viết thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async update({ commit, state }, payload) {
    try {
      if (await showConfirm('Bạn có chắc chắn muốn lưu không?')) {
        commit('setLoading', true);
        const response = await DataService.callApi(
          state.api.UPDATE,
          {
            ...payload,
            id: state.articles.id,
          },
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Cập nhật Bài viết thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async updateStatus({ commit, state }, payload) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(
        state.api.UPDATE_STATUS,
        {
          ...payload,
        },
        null
      );
      let success = false;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: 'Thông báo',
          description: 'Cập nhật Chủ đề thành công',
          duration: 4,
        });
      });
      return success;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async updateHotNews({ commit, state }, payload) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(
        state.api.UPDATE_HOT,
        {
          ...payload,
        },
        null
      );
      let success = false;
      checkResponse(response, () => {
        success = true;
        notification.success({
          message: 'Thông báo',
          description: 'Cập nhật bài viết thành công',
          duration: 4,
        });
      });
      return success;
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async delete({ state }, rows) {
    try {
      if (!rows.length) {
        notification.error({
          message: 'Thông báo',
          description: 'Không có bản ghi được chọn',
          duration: 4,
        });
        return false;
      }
      if (await showConfirm('Bạn có chắc chắn muốn xóa không?')) {
        const response = await DataService.delete(
          state.api.DELETE.url + '/' + rows.map((e) => e.code).join(',')
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Xóa Bài viết thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },

  async unPublic({ state }, rows) {
    try {
      if (!rows.length) {
        notification.error({
          message: 'Thông báo',
          description: 'Không có bản ghi được chọn',
          duration: 4,
        });
        return false;
      }
      if (await showConfirm('Bạn có chắc chắn muốn gỡ bài không?')) {
        const response = await DataService.callApi({
          url: state.api.UN_PUBLISH.url + '/' + rows.map((e) => e.id).join(','),
          method: state.api.PUBLISH.method,
        });
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Gỡ bài viết thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async approvalOrPublish({ state }, data) {
    try {
      if (
        await showConfirm(`Bạn có chắc chắn muốn ${data.statusName} không?`)
      ) {
        const response = await DataService.patch(
          data.isPublish ? state.api.PUBLISH.url : state.api.APPROVAL.url,
          data,
          null
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: `${data.statusName} bài viết thành công`,
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async setVisible({ state }, visible) {
    state.visible = visible;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
