import { FORM_MODE } from '@/util/common-constant';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  getAllSuccess(state, data) {
    state.list = data;
    state.loading = false;
  },
  getAll(state, data) {
    state.listAll = data.map((e) => ({ value: e.id, label: e.name }));
    state.loading = false;
  },
  getAllPublish(state, data) {
    state.listAllPublish = data.content.map(
      (e) => ({ value: e.id, label: e.title } || [])
    );
    state.loading = false;
  },
  preCreate(state) {
    state.visible = true;
    state.formMode = FORM_MODE.CREATE;
    state.mediaFile = {
      id: null,
      publishedTime: null,
      thumbPath: [],
      description: '',
      status: 1,
      title: '',
      view: 0,
      languageType: '',
      mediaType: null,
    };
  },
  setFormValue(state, { mediaFile, formMode }) {
    state.visible = true;
    state.formMode = formMode;
    console.log('articles,', mediaFile);
    state.mediaFile = {
      ...mediaFile,
      thumbPath: [mediaFile.thumbPath],
      isHot: mediaFile.isHot === 1,
    };
  },
};
