import mutations from './mutations';
import { DataService } from '@/dataService/dataService';
import ConstantAPI from '@/config/ConstantAPI';
import { TABLE_LINE_CHART } from '@/config/Constant';

const state = () => ({
  lineLoading: false,
  registerLoading: false,
  twitterOverviewData: null,
  totalArticleByStatus: null,
  totalArticleByCreated: null,
  twLoading: false,
  error: null,
  api: ConstantAPI.report_service,
});

const actions = {
  async loadTotalArticleByStatus({ state, commit }) {
    console.log('loadTotalArticleByStatus');
    try {
      const response = await DataService.callApi(
        state.api.TOTAL_ARTICLE_BY_STATUS
      );
      return commit('loadTotalArticleByStatus', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async loadTotalArticleByCreated({ state, commit }) {
    console.log('loadTotalArticleByCreated');
    try {
      const response = await DataService.callApi(
        state.api.TOTAL_ARTICLE_BY_CREATED
      );
      return commit('loadTotalArticleByCreated', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async loadLineChart({ state, commit }, { type }) {
    try {
      state.lineLoading = true;
      const responses = await Promise.all(
        Object.keys(TABLE_LINE_CHART).map(
          (k) =>
            new Promise((resolve, reject) =>
              DataService.callApi(state.api.OVERVIEW_LINE, null, {
                type,
                table: k,
              })
                .then((r) => resolve({ data: r.data, chart: k.toLowerCase() }))
                .catch((e) => reject(e))
            )
        )
      );
      commit('loadLineChartSuccess', {
        type,
        responses,
      });
      state.lineLoading = false;
    } catch (err) {
      state.lineLoading = false;
      console.log(err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
