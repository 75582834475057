export const FORM_MODE = {
  CREATE: 1,
  UPDATE: 2,
  VIEW: 3,
};

export const GENDERS = [
  {
    value: 1,
    label: 'Nam',
  },
  {
    value: 0,
    label: 'Nữ',
  },
  {
    value: 2,
    label: 'Khác',
  },
];

export const STATUS = [
  {
    value: 1,
    label: 'Hoạt động',
  },
  {
    value: 0,
    label: 'Không hoạt động',
  },
];
export const IS_PUBLIC_STATUS = [
  {
    value: 1,
    label: 'Hiển thị',
  },
  {
    value: 0,
    label: 'Không hiển thị',
  },
];

export const OBJECT_QA = [
  {
    value: 1,
    label: 'Cá nhân',
  },
  {
    value: 2,
    label: 'Tổ chức',
  },
  {
    value: 3,
    label: 'Tổng hợp',
  },
];
export const STATUS_APPROVAL = [
  {
    value: 1,
    label: 'Đã duyệt',
  },
  {
    value: 0,
    label: 'Chưa duyệt',
  },
  {
    value: -1,
    label: 'Từ chối',
  },
];

export const ARTICLE_STATUS_BAN_NHAP = 1;
export const ARTICLE_STATUS_GUI_DUYET = 2;
export const ARTICLE_STATUS_BIEN_TAP_LAI = 3;
export const ARTICLE_STATUS_CHO_XUAT_BAN = 4;
export const ARTICLE_STATUS_DUYET_LAI = 5;
export const ARTICLE_STATUS_XUAT_BAN = 6;
export const ARTICLE_STATUS_GO_BAI = 7;
export const STATUS_ARTICLE = [
  {
    value: ARTICLE_STATUS_BAN_NHAP,
    label: 'Bản nháp',
  },
  {
    value: ARTICLE_STATUS_GUI_DUYET,
    label: 'Gửi duyệt',
  },
  {
    value: ARTICLE_STATUS_BIEN_TAP_LAI,
    label: 'Biên tập lại ',
  },
  {
    value: ARTICLE_STATUS_CHO_XUAT_BAN,
    label: 'Chờ xuất bản',
  },
  {
    value: ARTICLE_STATUS_DUYET_LAI,
    label: 'Duyệt lại',
  },
  {
    value: ARTICLE_STATUS_XUAT_BAN,
    label: 'Xuất bản',
  },
  {
    value: ARTICLE_STATUS_GO_BAI,
    label: 'Gỡ bài',
  },
];
export const STATUS_ARTICLE_COLOR = [
  {
    value: ARTICLE_STATUS_BAN_NHAP,
    label: '#9196a4',
  },
  {
    value: ARTICLE_STATUS_GUI_DUYET,
    label: '#dcd724',
  },
  {
    value: ARTICLE_STATUS_BIEN_TAP_LAI,
    label: '#d966a0',
  },
  {
    value: ARTICLE_STATUS_CHO_XUAT_BAN,
    label: '#6941a4',
  },
  {
    value: ARTICLE_STATUS_DUYET_LAI,
    label: '#d0095a',
  },
  {
    value: ARTICLE_STATUS_XUAT_BAN,
    label: '#269322',
  },
  {
    value: ARTICLE_STATUS_GO_BAI,
    label: '#ccbfb1',
  },
];

export const AGENCY_TYPE = [
  {
    value: 1,
    label: 'Cơ quan',
  },
  {
    value: 2,
    label: 'Nhà mạng',
  },
  {
    value: 3,
    label: 'Bank',
  },
];

export const AGENCY_PROCESS = [
  {
    value: 0,
    label: 'Chưa có thông tin',
  },
  {
    value: 1,
    label: 'Chưa thực hiện',
  },
  {
    value: 2,
    label: 'Đang thực hiện',
  },
  {
    value: 3,
    label: 'Đã thực hiện',
  },
];

export const defaultButton = (menu) => {
  const buttonsForm = [
    { type: 'RESET' },
    { type: 'SEARCH' },
    { type: 'CREATE', permission: `${menu}:create` },
    { type: 'DELETE', permission: `${menu}:delete` },
  ];
  const buttonActions = [
    { type: 'EDIT', permission: `${menu}:edit` },
    { type: 'VIEW' },
  ];
  return { buttonsForm, buttonActions };
};

export const defaultButtonApproval = () => {
  const buttonsForm = [{ type: 'RESET' }, { type: 'SEARCH' }];
  const buttonActions = [{ type: 'APPROVAL' }, { type: 'CANCEL_APPROVAL' }];
  return { buttonsForm, buttonActions };
};
export const defaultButtonConfigAgency = () => {
  const buttonsForm = [{ type: 'RESET' }, { type: 'SEARCH' }];
  const buttonActions = [{ type: 'SHOW' }, { type: 'HIDDEN' }];
  return { buttonsForm, buttonActions };
};
export const HIDDEN_MENUS = ['/system/agency/detail'];
export const STATUS_AGENCY = {
  show: 1,
  hidden: 0,
};
export const STATUS_AGENCY_TEXT = [
  {
    value: 1,
    label: 'Hiển thị',
  },
  {
    value: 0,
    label: 'Ẩn',
  },
];

export const LANGUAGE_TYPE = [
  {
    value: 'vi',
    label: 'Tiếng Việt',
  },
  {
    value: 'en',
    label: 'English',
  },
];

export const MEDIA_TYPE = [
  {
    value: 2,
    label: 'Ảnh',
  },
  {
    value: 4,
    label: 'Video',
  },
];
export const TYPE_BANNER = [
  {
    value: 1,
    label: 'Đầu trang',
  },
  {
    value: 2,
    label: 'Thân trang',
  },
];

export const TYPE_AGENCY = [
  {
    value: 1,
    label: 'Cơ quan ban hành',
  },
  {
    value: 2,
    label: 'Cơ quan tiếp nhận',
  },
];

export const APP_PARAM_TYPE = {
  TYPE_OF_WORK: 'type_of_work',
  EXPERIENCE: 'experience',
  EMPLOYEE_LEVEL: 'employee_level',
  WORKPLACE: 'workplace',
};

export const LANGUAGE = {
  VIETNAMESE: 'vi',
  ENGLISH: 'en',
};

export const CONTACT_READ = 1;
export const CONTACT_UNREAD = 0;
export const STATUS_CONTACT = [
  {
    value: CONTACT_READ,
    label: 'Đã xem',
  },
  {
    value: CONTACT_UNREAD,
    label: 'Mới',
  },
];
